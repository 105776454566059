import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  computed,
  input,
  signal,
} from '@angular/core';
import { TranslatedError } from '@x/common/error';
import { FADE_IN_OUT_ANIMATION } from '../../animations';

@Directive({
  selector: '[xErrorResolverAction]',
})
export class ErrorResolverActionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

export class DashboardPanelCloseEvent {
  constructor(public isClosed: boolean) {}
}

@Component({
  selector: 'x-dashboard-panel',
  templateUrl: 'dashboard-panel.component.html',
  styleUrls: ['dashboard-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-dashboard-panel',
  },
  animations: [FADE_IN_OUT_ANIMATION],
})
export class DashboardPanelComponent {
  @Input()
  @HostBinding('class')
  position: 'side' | 'side-x' | 'side-m' | 'side-l' | 'half' | 'full' = 'full';

  panelLoading = input(false, { transform: coerceBooleanProperty });

  @HostBinding('class.closeable')
  closeable = input(false, { transform: coerceBooleanProperty });

  @Input()
  @HostBinding('class.closed')
  closed = false;

  @Output()
  closeChange = new EventEmitter<DashboardPanelCloseEvent>();

  @Input()
  set panelError(error: TranslatedError | TranslatedError[] | null | undefined) {
    if (error) {
      this.translatedErrors.set(Array.isArray(error) ? error : [error]);
    } else {
      this.translatedErrors.set([]);
    }
    this.showErrorDetail = false;
  }

  showErrorDetail = false;

  translatedErrors = signal<TranslatedError[]>([]);
  loadingTimeout: any = null;

  shouldDisplayError = computed(() => {
    return !this.panelLoading() && this.translatedErrors()?.length > 0;
  });

  toggleClose() {
    this.closed = !this.closed;
    this.closeChange.emit(new DashboardPanelCloseEvent(this.closed));
  }

  toggleErrorDetail() {
    this.showErrorDetail = !this.showErrorDetail;
  }
}
