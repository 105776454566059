import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { IconModule } from '@x/common/icon';
import { LocaleModule } from '@x/common/locale';
import { OperationModule } from '@x/common/operation';
import { ActionRowComponent } from './components/action-row/action-row.component';
import { CardComponent } from './components/card/card.component';
import { ClipDirective } from './components/clip/clip.component';
import { ContentComponent } from './components/content/content.component';
import { DashboardErrorsComponent } from './components/dashboard-errors/dashboard-errors.component';
import { DashboardLayoutSimplexComponent } from './components/dashboard-layout-simplex/dashboard-layout-simplex.component';
import { DashboardOperationHistoryComponent } from './components/dashboard-operation-history/dashboard-operation-history.component';
import { DashboardPanelComponent } from './components/dashboard-panel/dashboard-panel.component';
import { DashboardSidenavComponent } from './components/dashboard-sidenav/dashboard-sidenav.component';
import { DashboardToolbarComponent } from './components/dashboard-toolbar/dashboard-toolbar.component';
import { ListItemComponent } from './components/list/list-item.component';
import { OperationSnackbarComponent } from './components/operation-snackbar/operation-snackbar.component';
import { PanelSectionComponent } from './components/panel-section/panel-section.component';
import { PillGroupComponent } from './components/pill/pill-group.component';
import { PillComponent } from './components/pill/pill.component';
import {
  QueryBoundaryComponent,
  QueryBoundaryContentDirective,
} from './components/query-boundary/query-boundary.component';
import { CubeSpinnerComponent } from './components/spinner/cube-spinner.component';
import { DASHBOARD_LANGUAGE } from './dashboard-language';
import { FilterListPipe } from './pipes/filter-list.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { LimitPipe } from './pipes/limit.pipe';
import { DashboardSnackbarService } from './services/dashboard-snackbar.service';

const MATERIAL_MODULES = [
  TextFieldModule,
  LayoutModule,
  MatSidenavModule,
  MatButtonModule,
  MatCardModule,
  MatListModule,
  MatFormFieldModule,
  MatInputModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatSelectModule,
  MatToolbarModule,
  MatDialogModule,
  MatSnackBarModule,
  MatChipsModule,
  MatTableModule,
  MatPaginatorModule,
  MatRadioModule,
  MatSortModule,
  MatBadgeModule,
  MatTabsModule,
  MatMenuModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatButtonToggleModule,
  MatTreeModule,
  MatSlideToggleModule,
  MatRippleModule,
  CdkTreeModule,
  DragDropModule,
  MatDividerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatLuxonDateModule,
  ScrollingModule,
  A11yModule,
  MatBottomSheetModule,
  ClipboardModule,
];

const DECLARATIONS = [
  DashboardLayoutSimplexComponent,
  DashboardSidenavComponent,
  DashboardToolbarComponent,
  DashboardPanelComponent,
  DashboardErrorsComponent,
  DashboardOperationHistoryComponent,
  ActionRowComponent,
  ContentComponent,
  QueryBoundaryComponent,
  QueryBoundaryContentDirective,
  PanelSectionComponent,
  CubeSpinnerComponent,
  PillComponent,
  PillGroupComponent,
  OperationSnackbarComponent,
  LimitPipe,
  JoinPipe,
  FilterListPipe,
  ListItemComponent,
  ClipDirective,
  CardComponent,
];

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    ...MATERIAL_MODULES,
    OperationModule,
    LocaleModule.forChild(DASHBOARD_LANGUAGE),
  ],
  declarations: DECLARATIONS,
  exports: [...DECLARATIONS, ...MATERIAL_MODULES, IconModule],
  providers: [DashboardSnackbarService],
})
export class DashboardCoreModule {}
